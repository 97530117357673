
import { Inject } from 'inversify-props';
import { Prop, Vue, Component } from 'vue-property-decorator';
import moment from 'moment';
import EventsModel from '@/modules/events/models/events.model';
import UserService, { UserServiceS } from '@/modules/user/user.service';
import Flag from '@/modules/common/components/ui-kit/flag.vue';
import EventTypeIcon from '@/modules/common/components/ui-kit/event-type-icon.vue';
import UserViewService, { UserViewServiceS } from '@/modules/user/user-view.service';
import { USER_LEVELS } from '@/modules/user/constants';

@Component({
    components: { Flag, EventTypeIcon },
})
export default class PopupEvents extends Vue {
    @Inject(UserServiceS) private userService!: UserService;
    @Inject(UserViewServiceS) private userViewService!: UserViewService;

    @Prop({
        required: true,
        type: Array as () => EventsModel[],
    })
    eventsData!: EventsModel[];

    get isEventsAvailable() {
        return this.userService.isHotelUser
            || this.userService.isCarUser
            || this.userService.isChainUser
            || this.userService.isClusterUser;
    }

    get eventsRoute() {
        const { isCarUser } = this.userService;
        const routeBranch = this.$route.path.split('/')[1]!;

        return {
            name: isCarUser
                ? 'events-manager'
                : `${routeBranch}.events-manager`,
            params: {
                ...this.$route.params,
            },
        };
    }

    async openEvent(event: EventsModel) {
        if (!event.id) {
            return;
        }

        const { day, hotelId } = this.$route.params;
        const hotelIdParsed = parseInt(this.$route.params.hotelId, 10);

        // Must be selected hotel level to show correct events in view popup
        if (hotelIdParsed) {
            await this.userViewService.goToHotelPage(hotelIdParsed);
        }

        const routeLevel = this.userService.isCarUser ? null : USER_LEVELS.HOTEL;
        const baseRoute = 'events-manager.day-event-modal.view';
        const routeName = routeLevel ? `${routeLevel}.${baseRoute}` : baseRoute;

        this.$router.push({
            name: routeName,
            params: { eventId: event.id, hotelId, day },
        });
    }

    date(event: EventsModel): string {
        if (!event.startDate || !event.endDate) {
            return '';
        }

        if (event.startDate.getTime() === event.endDate.getTime()) {
            return moment(event.startDate).format('DD MMM');
        }

        const endDay = moment(event.endDate).date();
        const endMonth = moment(event.endDate).format('MMM');
        const startDay = moment(event.startDate).date();
        const startMonth = moment(event.startDate).format('MMM');

        const startMonthS = startMonth === endMonth
            ? ''
            : ` ${startMonth}`;
        return `${startDay}${startMonthS}-${endDay} ${endMonth}`;
    }
}
